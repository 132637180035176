<template>
    <div class="single-dropdown">
        <a v-for="(link, index) in links" :key="`single-dropdown-link-${index}`" :href="link.url" target="_blank">
            {{ link.title }}
        </a>
    </div>
</template>

<script>
    export default {
        name: "SingleDropDown",
        props: {
            links: {
                type: [Object, Array]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../styles/main.scss";

    .single-dropdown {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 180px;
        z-index: 3;
        box-shadow: rgba(29, 140, 242, 0.16) 8px -2px 19px 0px;
        left: 0;
        top: 70px;
        border-radius: 8px;

        a {
            color: #212529;
            text-decoration: none;
            padding: $default_margin;
            transition: background-color 300ms ease-out;

            &:first-of-type {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &:last-child {
                margin-bottom: 0px;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            &:hover {
                text-decoration: none;
                background-color: $navmenu_action_color;
            }
        }
    }
</style>